import React from 'react';
import { NavLink } from 'react-router-dom';

const Catagories = () => {
  return (
    <div className="pt-24 sm:pt-36 px-4 lg:px-24">
      <div>
        <h1 className="text-3xl sm:text-5xl font-bold pb-5 text-center">Our Services</h1>
      </div>
      <div className="relative pt-10">
        <div className="relative flex flex-wrap justify-around pt-4 items-center">
          <NavLink to="/it" className="relative m-2 sm:m-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 h-48 sm:h-60 lg:h-72 rounded-xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8">
            <img className="w-full h-full object-cover" src="/software.jpg" alt="IT & Software"/>
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-center">
              <h1 className="text-base sm:text-xl lg:text-2xl text-white py-2 sm:py-3">IT & Software</h1>
            </div>
          </NavLink>
          <NavLink to="/oil" className="relative m-2 sm:m-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 h-48 sm:h-60 lg:h-72 rounded-xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8">
            <img className="w-full h-full object-cover" src="/oil.jpg" alt="Oil Trade & Oil Refineries"/>
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-center">
              <h1 className="text-base sm:text-xl lg:text-2xl text-white py-2 sm:py-3">Oil Trade & Oil Refineries</h1>
            </div>
          </NavLink>
          <NavLink to="/man" className="relative m-2 sm:m-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 h-48 sm:h-60 lg:h-72 rounded-xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8">
            <img className="w-full h-full object-cover" src="/man.jpg" alt="Man Power Supply"/>
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-center">
              <h1 className="text-base sm:text-xl lg:text-2xl text-white py-2 sm:py-3">Man Power Supply</h1>
            </div>
          </NavLink>
          <NavLink to="/sanitaryware" className="relative m-2 sm:m-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 h-48 sm:h-60 lg:h-72 rounded-xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8">
            <img className="w-full h-full object-cover" src="/sanitary.webp" alt="Sanitaryware & Bathfittings"/>
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-center">
              <h1 className="text-base sm:text-xl lg:text-2xl text-white py-2 sm:py-3">Sanitaryware & Bathfittings</h1>
            </div>
          </NavLink>
          <NavLink to="/door" className="relative m-2 sm:m-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 h-48 sm:h-60 lg:h-72 rounded-xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8">
            <img className="w-full h-full object-cover" src="/door.jpg" alt="Doors & Windows"/>
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-center">
              <h1 className="text-base sm:text-xl lg:text-2xl text-white py-2 sm:py-3">Doors & Windows</h1>
            </div>
          </NavLink>
          <NavLink to="/tiles" className="relative m-2 sm:m-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 h-48 sm:h-60 lg:h-72 rounded-xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8">
            <img className="w-full h-full object-cover" src="/tiles.jpg" alt="Tiles & Surfaces"/>
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-center">
              <h1 className="text-base sm:text-xl lg:text-2xl text-white py-2 sm:py-3">Tiles & Surfaces</h1>
            </div>
          </NavLink>
          <NavLink to="/plumbing" className="relative m-2 sm:m-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 h-48 sm:h-60 lg:h-72 rounded-xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8">
            <img className="w-full h-full object-cover" src="/plumbings.jpg" alt="Plumbing pipes & Fittings"/>
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-center">
              <h1 className="text-base sm:text-xl lg:text-2xl text-white py-2 sm:py-3">Plumbing pipes & Fittings</h1>
            </div>
          </NavLink>
          <NavLink to="/cement" className="relative m-2 sm:m-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 h-48 sm:h-60 lg:h-72 rounded-xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8">
            <img className="w-full h-full object-cover" src="/cement.jpg" alt="Cements & Allied"/>
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-center">
              <h1 className="text-base sm:text-xl lg:text-2xl text-white py-2 sm:py-3">Cements & Allied</h1>
            </div>
          </NavLink>
          <NavLink to="/steel" className="relative m-2 sm:m-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 h-48 sm:h-60 lg:h-72 rounded-xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8">
            <img className="w-full h-full object-cover" src="/steel.jpg" alt="Steel"/>
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-center">
              <h1 className="text-base sm:text-xl lg:text-2xl text-white py-2 sm:py-3">Steel</h1>
            </div>
          </NavLink>

          <NavLink to="/electronics" className="relative m-2 sm:m-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 h-48 sm:h-60 lg:h-72 rounded-xl border border-gray-300 overflow-hidden hover:scale-105 cursor-pointer mb-8">
            <img className="w-full h-full object-cover" src="/electronics.avif" alt="Electronics"/>
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-center">
              <h1 className="text-base sm:text-xl lg:text-2xl text-white py-2 sm:py-3">Electronics</h1>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Catagories;
