import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';

const Plumbing = () => {
  const plumbingCategories = [
    { src: "./pipe2.webp", alt: "pipe2", text: "Water Tanks (3027)" },
    { src: "./pipe3.webp", alt: "pipe3", text: "Pipe Fittings (227)" },
    { src: "./pipe4.webp", alt: "pipe4", text: "pipes (257)" },
    { src: "./pipe5.webp", alt: "pipe5", text: "Tube Pipes (187)" },
  ];

  const plumbingItems = [
    { src: "./pipe6.webp", alt: "pipe6", name: "TATA DI Pipe K-9 - 350 mm", price: "" },
    { src: "./pipe7.webp", alt: "pipe7", name: "Astral PVC Agri Pipe - 160 mm - 20 ft", price: "" },
    { src: "./pipe4.webp", alt: "pipe4", name: "Neco CI Hubless Pipe - 50 mm", price: "" },
    { src: "./pipe6.webp", alt: "pipe6", name: "Astral UPVC Socket - 160 mm", price: "" },
    { src: "./pipe5.webp", alt: "pipe5", name: "Neco CI Hubless Bend - 90 Deg - 150 mm", price: "" },
  ];

  return (
    <div>
      <Fixed />
      <div className="pt-20 md:pt-40">
        <div className='relative' style={{ height: '500px' }}>
          <img className="opacity-80 w-full h-full object-cover" src="./pipe1.webp" alt='sanitary' />
          <h1 className="text-3xl md:text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-800 font-bold">Plumbing Pipes & Fittings</h1>
        </div>
      </div>

      <div className="p-4 sm:p-14 pt-14 md:pt-28 flex flex-wrap justify-center">
        {plumbingCategories.map(({ src, alt, text }, index) => (
          <div key={index} className="rounded-s-full flex flex-col mx-2 md:mx-6 mb-4 md:mb-8">
            <img className="w-16 md:w-40 h-16 md:h-40 rounded-full border-2 border-black mx-auto mb-2" src={src} alt={alt} />
            <p className="text-xs md:text-lg text-center font-semibold">{text}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-col pt-10 md:pt-20 items-center">
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 w-full md:w-2/3'>
          {plumbingItems.map(({ src, alt, name, price }, index) => (
            <div key={index} className='flex flex-col border p-2 md:p-5 hover:shadow-2xl cursor-pointer m-1 md:m-2'>
              <img className='h-40 md:h-96 w-full' src={src} alt={alt} />
              <h1 className="text-sm md:text-2xl font-semibold p-1 md:p-3">{name}</h1>
              <p className="text-sm md:text-xl font-semibold p-1 md:p-4">{price}</p>
              <NavLink to="/service" className="bg-blue-950 text-white p-2 md:p-4 text-sm md:text-xl font-semibold rounded-xl">Request One</NavLink>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Plumbing;
