import React from 'react';
import Marquee from "react-fast-marquee";

const Brands = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center pt-20 sm:pt-40 px-4">
      <div className="w-full flex flex-col">
        <div className="flex space-y-2 flex-col text-center mb-8 sm:mb-14 pb-4 sm:pb-8">
          <span className="text-3xl sm:text-5xl font-bold">
            Our Brands
          </span>
        </div>
        <Marquee>
          <img className="h-10 sm:h-20 w-auto px-2 sm:px-6" src='./jcsgr.png' alt='JCSGR' />
          <img className="h-10 sm:h-16 w-auto px-2 sm:px-4" src='./carry.png' alt='Carry' />
          <img className="h-10 sm:h-14 w-auto  sm:px-6" src='./srirama.png' alt='Srirama' />
          <img className="h-14 sm:h-20 w-auto  sm:px-2" src='./sunotal.png' alt='Sunotal' />
          <img className="h-10 sm:h-20 w-auto" src='./jtv.png' alt='JTV' />
          <img className="h-10 sm:h-20 w-auto" src='./jpay1.png' alt='JPay1' />
          <img className="h-10 sm:h-20 w-auto  sm:px-6" src='./jfarm.jpg' alt='jfarm' />
          <img className="h-10 sm:h-20 w-auto  sm:px-6" src='./jfc.jpg' alt='jfc' />
        </Marquee>
      </div>
    </div>
  );
};

export default Brands;
