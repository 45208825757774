import React, { useState, useEffect } from 'react';

const Work = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 880);
    };

    handleResize(); // Check initial size
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="pt-32">
      <h1 className="text-5xl font-bold">How We Work</h1>
      <div className="flex justify-center pt-16">
        {isMobile ? (
          <img className="w-4/5" src="./work2.webp" alt="workimage" />
        ) : (
          <img className="w-4/5" src="./work1.webp" alt="workimage" />
        )}
      </div>
    </div>
  );
};

export default Work;
