import React from 'react'
import Fixed from './Fixed'
import Catagories from './Catagories'
import Footer from './Footer'

const ShopByCatagories = () => {
  return (
    <div>
        <Fixed />
        <Catagories />
        <Footer />
    </div>
  )
}

export default ShopByCatagories;