import React, { useState, useEffect } from 'react';

const Why = () => {
  // State to track the window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check if the screen size is less than 850px
  const isMobile = windowWidth < 850;

  return (
    <div className="pt-16 sm:pt-32 px-4">
      <h1 className={`text-3xl sm:text-5xl font-bold py-5 ${isMobile ? 'text-center' : ''}`}>Why JCS Global</h1>
      <p className={`text-lg sm:text-2xl text-gray-600 font-medium ${isMobile ? 'text-center' : ''}`}>
        Helping you to transform the way you produce building raw materials
      </p>
      <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} justify-center items-center p-8 sm:p-14`}>
        <div className={`flex flex-col justify-around px-4 sm:px-10 ${isMobile ? 'mb-8 text-center' : ''}`}>
          <svg className={`h-10 sm:h-14 w-auto mx-auto`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
          </svg>
          <div className="pt-3">
            <h1 className={`text-lg sm:text-xl`}>2500+</h1>
            <h1 className={`text-lg sm:text-xl`}>Users</h1>
          </div>
        </div>
        <div className={`flex flex-col justify-around px-4 sm:px-10 ${isMobile ? 'mb-8 text-center' : ''}`}>
          <svg className={`h-10 sm:h-14 w-auto mx-auto`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
          </svg>
          <div className="pt-3">
            <h1 className={`text-lg sm:text-xl`}>Good</h1>
            <h1 className={`text-lg sm:text-xl`}>Products</h1>
          </div>
        </div>
        <div className={`flex flex-col justify-around px-4 sm:px-10 ${isMobile ? 'mb-8 text-center' : ''}`}>
          <svg className={`h-10 sm:h-14 w-auto mx-auto`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
          </svg>
          <div className="pt-3">
            <h1 className={`text-lg sm:text-xl`}>On time</h1>
            <h1 className={`text-lg sm:text-xl`}>Delivery</h1>
          </div>
        </div>
        <div className={`flex flex-col justify-around px-4 sm:px-10 ${isMobile ? 'text-center' : ''}`}>
          <svg className={`h-10 sm:h-14 w-auto mx-auto`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
          </svg>
          <div className="pt-3">
            <h1 className={`text-lg sm:text-xl`}>Quality</h1>
            <h1 className={`text-lg sm:text-xl`}>Assurance</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
