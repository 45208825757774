import React from 'react'
import Fixed from './Fixed'
import Firstimage from './Firstimage'
import Catagories from './Catagories'
import Brands from './Brands'
import Connect from './Connect'
import Work from './Work'
import Why from './Why'
import Footer from './Footer'

const Body = () => {
  return (
    <div>
        <div className="fixed w-full z-10">
        <Fixed />
        </div>

        <Firstimage />
        <Catagories />
        <Brands />
        <Connect />
        <Work />
        <Why />
        <Footer />

    </div>
  )
}

export default Body