import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NavLink } from 'react-router-dom';

const Firstimage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: dots => (
      <div className="absolute bottom-5 left-0 right-0 flex justify-center pt-5">
        <ul className="flex justify-center">{dots}</ul>
      </div>
    ),
    customPaging: i => <div className="h-3 w-3 mx-2 bg-slate-700 rounded-full" />,
  };

  return (
    <div className="pt-32">
      <Slider {...settings}>
        <div className="relative w-full h-auto">
          <img className="w-full h-auto" src="./front6.jpg" alt="Slide 1" />
          <div className={`absolute ${isMobile ? 'top-2' : 'top-0'} left-0 bottom-0 ${isMobile ? 'w-3/4' : 'w-1/2'} h-full flex flex-col justify-center items-start`}>
            <div className={`p-5 rounded-xl ${isMobile ? 'py-8' : 'py-16'} ${isMobile ? 'text-sm' : ''}`}>
              <h1 className={`text-cyan-800 to-blue-500 ${isMobile ? 'text-2xl' : 'text-7xl'} font-bold`}>From Steel to Tiles</h1>
              <p className={`text-gray-700 ${isMobile ? 'text-xs' : 'text-3xl'} pt-5`}>Explore our comprehensive selection of building materials available online, ideal for residential, commercial, and infrastructure projects alike.</p>
              <h1 className={`text-gray-700 ${isMobile ? 'text-xs' : 'text-3xl'} pt-5`}>18000+ SKUs | 150+ Brands</h1>
              <div className="pt-8">
              <NavLink to="/service" className={`bg-gradient-to-r from-pink-300 via-blue-500 to-sky-300 p-2 rounded-xl hover:scale-105 ${isMobile ? 'text-sm' : 'text-xl'} text-white`}>Get Service</NavLink>
              </div>
            </div>
          </div>
        </div>
        
        <div className="relative w-full h-auto">
          <img className="w-full h-auto" src="./front2.jpg" alt="Slide 2" />
          <div className={`absolute ${isMobile ? 'top-2' : 'top-0'} left-0 bottom-0 ${isMobile ? 'w-3/4' : 'w-2/5'} h-full flex flex-col justify-center`}>
            <div className={`p-5 rounded-xl ${isMobile ? 'py-8' : 'py-16'} ${isMobile ? 'text-sm' : ''}`}>
              <h1 className={`text-indigo-800 ${isMobile ? 'text-2xl' : 'text-7xl'} font-bold`}>Refining Your Goals</h1>
              <p className={`text-gray-700 ${isMobile ? 'text-xs' : 'text-3xl'} pt-5`}>Integrated Procurement Solutions with Financing Options for Building Materials.</p>
              <h1 className={`text-gray-700 ${isMobile ? 'text-xs' : 'text-3xl'} pt-5`}>Competitive Pricing | Timely Deliveries | Ensured Quality</h1>
              <div className="pt-8">
              <NavLink to="/service" className={`bg-gradient-to-r from-pink-300 via-blue-500 to-sky-300 p-2 rounded-xl hover:scale-105 ${isMobile ? 'text-sm' : 'text-xl'} text-white`}>Get Service</NavLink>
              </div>
            </div>
          </div>
        </div>
        
        <div className="relative w-full h-auto">
          <img className="w-full h-auto" src="./front5.jpg" alt="Slide 3" />
          <div className={`absolute ${isMobile ? 'top-2' : 'top-0'} left-0 bottom-0 ${isMobile ? 'w-3/4' : 'w-2/5'} h-full flex flex-col justify-center`}>
            <div className={`p-5 rounded-xl ${isMobile ? 'py-8' : 'py-16'} ${isMobile ? 'text-sm' : ''}`}>
              <h1 className={`text-gray-900 ${isMobile ? 'text-2xl' : 'text-7xl'} font-bold`}>Tailored Solutions</h1>
              <p className={`text-gray-700 ${isMobile ? 'text-xs' : 'text-3xl'} pt-5`}>Optimizing Supply Chains: Navigating the Logistics Landscape</p>
              <p className={`text-blue ${isMobile ? 'text-xs' : 'text-2xl'} pt-5`}>Multiple programs | Easy Eligibility check | Collateral-free credit line</p>
              <div className="pt-8">
              <NavLink to="/service" className={`bg-gradient-to-r from-pink-300 via-blue-500 to-sky-300 p-2 rounded-xl hover:scale-105 ${isMobile ? 'text-sm' : 'text-xl'} text-white`}>Get Service</NavLink>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Firstimage;
