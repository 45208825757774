import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';

const Tiles = () => {
  const tileCategories = [
    { src: "./tile2.webp", alt: "tile2", text: "Ceramic Tiles(327)" },
    { src: "./tile3.webp", alt: "tile3", text: "Wall Tiles(223)" },
    { src: "./tile4.webp", alt: "tile4", text: "Vitrified Tiles(257)" },
    { src: "./tile5.webp", alt: "tile5", text: "Outdoor Tiles(187)" },
  ];

  const tileItems = [
    { src: "./tile6.webp", alt: "tile6", name: "Ultra Strong Outdoor Vitrified Tiles - 400 mm x 400 mm - Estuco Beige Decore", price: "" },
    { src: "./tile7.webp", alt: "tile7", name: "Ultra Strong Outdoor Vitrified Tiles - 400 mm x 400 mm - Tarraco Beige", price: "" },
    { src: "./tile8.webp", alt: "tile8", name: "Exterior Floor Tiles - 300 mm x 300 mm - Nestro Teracotta", price: "" },
    { src: "./tile9.webp", alt: "tile9", name: "Exterior Floor Tiles - 300 mm x 300 mm - Platform Star Beige", price: "" },
    { src: "./tile10.webp", alt: "tile10", name: "Ceramic Floor Tiles - 600 mm x 600 mm - Avoca Black", price: "" },
    { src: "./tile11.webp", alt: "tile11", name: "Kota Stone Tiles - 2 ft x 2 ft ", price: "" },
  ];

  return (
    <div>
      <Fixed />
      <div className="pt-20 md:pt-40">
        <div className='relative' style={{ height: '700px' }}>
          <img className="opacity-80 w-full h-full object-cover" src="./tile1.webp" alt='tiles' />
          <h1 className="text-3xl md:text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-orange-400 font-bold">Tiles & Surfaces</h1>
        </div>
      </div>

      <div className="p-4 sm:p-14 pt-12 md:pt-28 flex flex-wrap justify-center">
        {tileCategories.map(({ src, alt, text }, index) => (
          <div key={index} className="rounded-full flex flex-col mx-2 md:mx-6 mb-4 md:mb-8">
            <img className="w-16 h-16 md:w-40 md:h-40 rounded-full border-2 border-black mx-auto mb-2" src={src} alt={alt} />
            <p className="text-xs md:text-lg text-center font-semibold">{text}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-col pt-10 md:pt-20 items-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 w-full md:w-2/3">
          {tileItems.map(({ src, alt, name, price }, index) => (
            <div key={index} className='flex flex-col border p-2 md:p-5 hover:shadow-2xl cursor-pointer m-1 md:m-2'>
              <img className='h-40 md:h-96 w-full' src={src} alt={alt} />
              <h1 className="text-sm md:text-2xl font-semibold p-1 md:p-3">{name}</h1>
              <p className="text-sm md:text-xl font-semibold p-1 md:p-4">{price}</p>
              <NavLink to="/service" className="bg-blue-950 text-white p-2 md:p-4 text-sm md:text-xl font-semibold rounded-xl">Request One</NavLink>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Tiles;
