import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Fixed from './Fixed';
import Footer from './Footer';

const Service = () => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [service, setService] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    // Prepare the email template parameters
    const templateParams = {
      name,
      mobile,
      service,
    };

    // Debugging log to check template parameters
    console.log('Template Params:', templateParams);

    // Send email using EmailJS
    emailjs.send('service_f7s5t5o', 'template_0nkv6qi', templateParams, 'g5OwaWP2aokcejzwz')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Your form has been submitted successfully!');

        // Clear form fields
        setName('');
        setMobile('');
        setService('');
      })
      .catch((error) => {
        console.error('FAILED...', error);
        alert('There was an error submitting your form. Please try again.');
      });
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Fixed />

      <div className="pt-32 flex-grow flex flex-col justify-center items-center bg-gray-100">
        <div className="bg-white shadow-lg rounded-lg p-10 w-full max-w-lg">
          <h1 className="font-bold text-4xl text-gray-800 mb-8 text-center">Fill the form to get Service</h1>
          <form onSubmit={handleSubmit} className="space-y-8">
            <div>
              <label htmlFor="name" className="block text-lg font-medium text-gray-700 mb-2">Name</label>
              <input
                type="text"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="mobile" className="block text-lg font-medium text-gray-700 mb-2">Mobile Number</label>
              <input
                type="tel"
                id="mobile"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="service" className="block text-lg font-medium text-gray-700 mb-2">Select Service</label>
              <select
                id="service"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
                value={service}
                onChange={(e) => setService(e.target.value)}
                required
              >
                <option value="">Choose a service</option>
                <option value="IT & Software">IT & Software</option>
                <option value="Oil Trade & Oil Refineries">Oil Trade & Oil Refineries</option>
                <option value="Man Power Supply">Man Power Supply</option>
                <option value="Steel">Steel</option>
                <option value="Plumbing Pipes & Fittings">Plumbing Pipes & Fittings</option>
                <option value="Sanitaryware & BathFittings">Sanitaryware & BathFittings</option>
                <option value="Cement & Allied Products">Cement & Allied Products</option>
                <option value="Doors & Windows">Doors & Windows</option>
                <option value="Tiles & Surfaces">Tiles & Surfaces</option>
                <option value="Electronics">Electronics</option>

              </select>
            </div>
            <button
              type="submit"
              className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-4 text-center"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Service;
