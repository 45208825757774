import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';

const AboutUs = () => {
  return (
    <div>
      <Fixed />
      <div className="pt-32 sm:pt-52 h-64">
        <h1 className="text-3xl sm:text-5xl h-64 bg-blue-950 text-white pt-20 sm:pt-28 font-bold">About Us</h1>
      </div>
      
      <div className="pt-32 sm:pt-56 px-4 sm:px-0">
        <p className="pt-10 sm:pt-28 mx-2 sm:mx-14 text-lg sm:text-2xl font-semibold text-center sm:text-left">
          Our mission is to build the most trusted B2B e-commerce platform that helps SMEs unlock their potential by transforming the way they do their business. We empower contractors and retailers to procure high-quality construction raw materials from top brands at competitive prices, with easy access to finance and a smooth delivery experience. Our platform also provides suppliers with the opportunity to grow their market and increase capacity utilization. We are a technology-first brand that is digitizing the entire ecosystem, making it more efficient, transparent, and accessible for businesses.
        </p>
      </div>

      <div className="pt-20 sm:pt-44">
       
      </div>

      <div className="pt-32 sm:pt-52">
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;
