import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Body from './Body';
import Sanitaryware from './Sanitaryware';
import Cement from './Cement';
import Steel from './Steel';
import Doors from './Doors';
import Tiles from './Tiles';
import Plumbing from './Plumbing';
import AboutUs from './AboutUs';
import Contact from './Contact';
import ShopByCatagories from './ShopByCatagories';
import It from './It';
import Man from './Man';
import Oil from './Oil';
import Electronics from './Electronics';
import Service from './Service';


const Home = () => {
  const appRouter = createBrowserRouter([
    {
      path: '/',
      element: <Body />
    },
    {
      path: '/about-us',
      element: <AboutUs />
    },
    {
      path: '/contact-us',
      element: <Contact />
    },
    {
      path: '/shop-by-catagories',
      element: <ShopByCatagories />
    },
    {
      path: '/sanitaryware',
      element: <Sanitaryware />
    },
    {
      path: '/cement',
      element: <Cement />
    },
    {
      path: '/steel',
      element: <Steel />
    },
    {
      path: '/door',
      element: <Doors />
    },
    {
      path: '/tiles',
      element: <Tiles />
    },
    {
      path: '/plumbing',
      element: <Plumbing />
    },
    {
      path: '/it',
      element: <It />
    },
    {
      path: '/man',
      element: <Man />
    },
    {
      path: '/oil',
      element: <Oil />
    },
    {
      path: '/electronics',
      element: <Electronics />
    },
    {
      path: '/service',
      element: <Service />
    }

    
  ]);

  return (
    <RouterProvider router={appRouter} />
  );
}

export default Home;
