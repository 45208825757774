import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';

const It = () => {
  return (
    <div>
      <Fixed />
      <div className="pt-20 md:pt-40">
        <div className='relative' style={{ height: '500px' }}>
          <img className="opacity-80 w-full h-full object-cover" src="./software1.jpg" alt='sanitary' />
          <h1 className="text-3xl md:text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-bold">IT & Software</h1>
        </div>
      </div>

      <div className="pt-14 md:pt-36 p-4 md:p-20 flex flex-wrap justify-center items-center">
        <div className="flex border h-auto w-full sm:w-96 m-2 md:m-6 hover:shadow-2xl cursor-pointer">
          <div className="p-4">
            <h1 className="font-bold text-xl md:text-3xl py-8 md:py-14">AI & ML</h1>
            <p className="text-base md:text-xl p-2 md:p-5">JCS Global through AI & ML-driven applications and integration services help our client’s businesses to add intelligence to their environment. Hire AI-ML expert engineers from Sunotal a strong AI application development company that has mastered AI and ML tools and technologies.</p>
          </div>
        </div>

        <div className="flex border h-auto w-full sm:w-96 m-2 md:m-6 hover:shadow-2xl cursor-pointer">
          <div className="p-4">
            <h1 className="font-bold text-xl md:text-3xl py-8 md:py-14">ERP</h1>
            <p className="text-base md:text-xl p-2 md:p-5">JCS Global brings a business-aligned view of ERP implementations. We help our clients map the risks and opportunities of ERP solutions, including lifecycle impacts. This collaborative approach to choosing the right solution and its lifecycle planning proactively accommodates short and long-term business goals, planning for change.</p>
          </div>
        </div>

        <div className="flex border h-auto w-full sm:w-96 m-2 md:m-6 hover:shadow-2xl cursor-pointer">
          <div className="p-4">
            <h1 className="font-bold text-xl md:text-3xl py-8 md:py-14">Cyber Security</h1>
            <p className="text-base md:text-xl p-2 md:p-5">Overview of Cyber Security Sunotal help you create a secure network and organization.With all of our online devices at risk, cybersecurity is one of the most crucial tasks for every organization. If you have even a single customer, you need to ensure cybersecurity to offer safe, reliable services to your users.</p>
          </div>
        </div>

        <div className="flex border h-auto w-full sm:w-96 m-2 md:m-6 hover:shadow-2xl cursor-pointer">
          <div className="p-4">
            <h1 className="font-bold text-xl md:text-3xl py-8 md:py-14">Devops</h1>
            <p className="text-base md:text-xl p-2 md:p-5">DevOps is a set of practices that combines software development and IT operations. It aims to shorten the systems development life cycle and provide continuous delivery with high software quality. DevOps is complementary with Agile software development; several DevOps aspects came from Agile methodology.</p>
          </div>
        </div>

        <div className="flex border h-auto w-full sm:w-96 m-2 md:m-6 hover:shadow-2xl cursor-pointer">
          <div className="p-4">
            <h1 className="font-bold text-xl md:text-3xl py-8 md:py-14">Cloud Infra</h1>
            <p className="text-base md:text-xl p-2 md:p-5">JCS Global is proactively supporting various enterprises in Cloud technologies. As part of our commitment we have developed Cloud strategy, architectures and designs. Depending upon the business requirements and technology roadmap Sunotal prescribes, deploy and maintains various cloud solutions and architecture.</p>
          </div>
        </div>

        <div className="flex border h-auto w-full sm:w-96 m-2 md:m-6 hover:shadow-2xl cursor-pointer">
          <div className="p-4">
            <h1 className="font-bold text-xl md:text-3xl py-8 md:py-14">Professional Services</h1>
            <p className="text-base md:text-xl p-2 md:p-5">JCS Global is a one-of-its-kind company offering an assortment of services to clients across diverse industries. Here are some of our popular outsourced services. Our professional developers has the technical expertise and over a decade of experience in emerging software technologies.</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default It;
